




































































































import Vue, { PropType } from 'vue';
// import { MessageEventBus } from '@/libs/EventBus';
import { MemberGroupPreviewModel } from '@/apis/models/MemberGroupModel';

export default Vue.extend({
  name: 'MemberGroupCard',
  components: {
  },
  props: {
    group: {
      type: Object as PropType<MemberGroupPreviewModel>,
      required: false,
      default: null as MemberGroupPreviewModel | null,
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultThumbnial:
      'https://images.unsplash.com/photo-1565057748808-18c797216b0c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80',
    };
  },
  computed: {
    previewImage(): string {
      if (this.defaultThumbnial) {
        return `${this.defaultThumbnial}/240`;
      }
      return this.defaultThumbnial;
    },
    dialogId(): string {
      return this.group.uuid;
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
  },
  mounted() {
    // MessageEventBus.$on('clear-copied-message', this.clearCopyMessageTimeout);
  },
  methods: {
    enterGroup() {
      this.$store.commit('updateEditPermission', true);
      this.$store.commit('changeMemberGroup', this.group);
      this.$router.push('/manager/membergroup-preview');
    },
    checkEnterGroup() {
      if (!this.selectModeOpen) { // 在非選取時刻
        this.enterGroup();
      }
    },
    removeGroup() {
      this.openRemoveModal();
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              // remove operation
              this.$modal.hide('dialog');
              this.$emit('removeMe');
            },
          },
        ],
      });
    },
    openShareMappingModal() {
      this.$modal.show('manage-share-mapping-modal', {
        sourceObjectId: this.group.uuid,
      });
    },
    // copyGroupCode() {
    //   MessageEventBus.$emit('clear-copied-message');
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   this.$copyText(`${this.group.uniqueName}`).then((e) => {
    //     this.copyMessageDisplay = true;
    //     this.copyMessageTimeoutId = setTimeout(this.clearCopyMessage, 1500);
    //   }, (e) => {
    //     console.error('複製失败', e.text);
    //   });
    // },
    // clearCopyMessage() {
    //   this.copyMessageDisplay = false;
    // },
    // clearCopyMessageTimeout() {
    //   this.clearCopyMessage();
    //   clearTimeout(this.copyMessageTimeoutId);
    // },
  },
});
