
































import { WorkspacePathModel } from '@/apis/models/WorkspaceObjectModel';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    path: {
      type: Array as () => Array<WorkspacePathModel>,
      required: true,
    },
    situation: {
      type: String,
      default: 'workspaceBrower',
    },
  },
  methods: {
    visitFolder(folderId: string) {
      this.$emit('visitFolder', folderId);
    },
  },
});
