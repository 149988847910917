




























































import Vue, { PropType } from 'vue';
import { WorkspaceSoftLinkModel, Permission } from '@/apis/models/WorkspaceSoftLinkModel';
import { WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';
import { QuizGroupPreviewModel } from '@/apis/models/QuizGroupModel';
import { MemberGroupPreviewModel } from '@/apis/models/MemberGroupModel';

export default Vue.extend({
  name: 'SharedObjectCard',
  components: {
  },
  props: {
    sharedObject: {
      type: Object as PropType<WorkspaceSoftLinkModel>,
      required: true,
    },
    displayName: {
      type: String,
      required: false,
      default: '',
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Permission,
    };
  },
  computed: {
    isValidSoftLink(): boolean {
      return Boolean(this.sharedObject.sourceObject);
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
    permissionText(): string {
      if (this.sharedObject) {
        switch (this.sharedObject.permission) {
          case Permission.EDITOR:
            return this.$t('可編輯').toString();
          case Permission.DETAIL_VIEWER:
            return this.$t('可檢視').toString();
          case Permission.GENERAL_VIEWER:
            return this.$t('僅可使用').toString();
          default:
            return this.$t('無權限').toString();
        }
      }
      return this.$t('無權限').toString();
    },
    onwerText(): string {
      if (this.sharedObject && this.sharedObject.sourceObject) {
        return this.sharedObject.sourceObject.metadata.owner.username;
      }
      return this.$t('未知').toString();
    },
    infoText(): string {
      const { sourceObject } = this.sharedObject;

      if (!sourceObject) {
        return '';
      }

      switch (sourceObject.metadata.type) {
        case WorkspaceObjectType.QuizGroup:
          return this.$tc('n題', (sourceObject as QuizGroupPreviewModel).quizNumber, { n: (sourceObject as QuizGroupPreviewModel).quizNumber }).toString();
        case WorkspaceObjectType.MemberGroup:
          return this.$tc('n人', (sourceObject as MemberGroupPreviewModel).memberCount, { n: (sourceObject as MemberGroupPreviewModel).memberCount }).toString();
        // case WorkspaceObjectType.QuizEvent:
        //   return `${(sourceObject as QuizEventPreviewModel)} 題`;
        default:
          return '';
      }
    },
    displayNameText(): string {
      if (this.displayName) { // empty string is falsy too
        return this.displayName;
      }

      const { sourceObject } = this.sharedObject;

      if (!sourceObject) {
        return '';
      }

      switch (sourceObject.metadata.type) {
        case WorkspaceObjectType.QuizGroup:
        case WorkspaceObjectType.Folder:
        case WorkspaceObjectType.QuizEvent:
          return sourceObject.metadata.name;
        case WorkspaceObjectType.MemberGroup:
          return (sourceObject as MemberGroupPreviewModel).uniqueName;
          // return (sourceObject as MemberGroupPreviewModel).metadata.name;
        default:
          return '';
      }
    },
    canViewDetail(): boolean {
      return (this.sharedObject.permission === Permission.EDITOR) || (this.sharedObject.permission === Permission.DETAIL_VIEWER);
    },
    canEdit(): boolean {
      return (this.sharedObject.permission === Permission.EDITOR);
    },
  },
  methods: {
    viewDetail() {
      this.enterGroup();
    },
    checkEnterGroup() {
      if (!this.selectModeOpen) { // 在非選取時刻
        this.enterGroup();
      }
    },
    goEdit() {
      if (this.sharedObject.sourceObject.metadata.type === 'WorkspaceQuizGroup') {
        this.$store.commit('changeQuizGroup', this.sharedObject.sourceObject);
        this.$router.push('/manager/quizgroup-maker');
      } else if (this.sharedObject.sourceObject.metadata.type === 'WorkspaceMemberGroup') {
        this.$store.commit('changeMemberGroup', this.sharedObject.sourceObject);
        this.$router.push('/manager/membergroup-maker');
      }
    },
    enterGroup() {
      if (!this.canEdit) { // 如果shareCard沒有編輯權限就不行編輯
        this.$store.commit('updateEditPermission', false);
      } else {
        this.$store.commit('updateEditPermission', true);
      }
      if (this.sharedObject.permission === Permission.GENERAL_VIEWER) {
        // eslint-disable-next-line no-alert
        window.alert('權限不足，僅可使用');
      } else if (this.sharedObject.sourceObject.metadata.type === 'WorkspaceQuizGroup') {
        this.$store.commit('changeQuizGroup', this.sharedObject.sourceObject);
        this.$router.push('/manager/quizgroup-preview');
      } else if (this.sharedObject.sourceObject.metadata.type === 'WorkspaceMemberGroup') {
        this.$store.commit('changeMemberGroup', this.sharedObject.sourceObject);
        this.$router.push('/manager/membergroup-preview');
      }
    },
    remove() {
      this.openRemoveModal();
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              this.$modal.hide('dialog');
              this.$emit('removeMe');
            },
          },
        ],
      });
    },
  },
});
