




















































import { WorkspaceObjectModel, WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';
import { SelectionEventBus } from '@/libs/EventBus';
import WorkspaceCard from '@/components/workspace/WorkspaceCard.vue';
import Vue, { PropType } from 'vue';
import { WorkspaceSoftLinkModel } from '@/apis/models/WorkspaceSoftLinkModel';
import WorkspaceObjectHandler from '@/handlers/WorkspaceObjectHandler';

export default Vue.extend({
  components: {
    WorkspaceCard,
  },
  props: {
    workspaceObject: {
      type: Object as PropType<WorkspaceObjectModel>,
      required: true,
    },
    defaultSelected: {
      type: Boolean,
      required: true,
    },
    displayMode: {
      type: String,
      default: 'default',
    },
    allowSelectMany: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array as () => Array<WorkspaceObjectModel>,
      required: true,
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      WorkspaceObjectType,
      selected: false,
    };
  },
  computed: {
    isFolder(): boolean {
      let { type } = this.workspaceObject.metadata;

      if (type === WorkspaceObjectType.SoftLink) {
        type = (this.workspaceObject as WorkspaceSoftLinkModel).sourceObject.metadata.type;
      }

      return type === WorkspaceObjectType.Folder;
    },
    displayName(): string {
      return WorkspaceObjectHandler.getObjectName(this.workspaceObject);
    },
  },
  watch: {
    selectModeOpen() {
      this.init();
    },
  },
  mounted() {
    this.selected = this.defaultSelected;
    SelectionEventBus.$on('reset', () => {
      this.selected = this.defaultSelected;
    });
  },
  methods: {
    init() {
      if (this.selectModeOpen === false) {
        this.selected = false;
      }
    },
    removeGroupCard() {
      this.$emit('removeMe');
    },
    visit() {
      if (this.isFolder) {
        let folderId = this.workspaceObject.uuid;
        if (this.workspaceObject.metadata.type === WorkspaceObjectType.SoftLink) {
          folderId = (this.workspaceObject as WorkspaceSoftLinkModel).sourceObject.uuid;
        }

        console.log(folderId);

        this.$emit('visit', folderId);
      }
    },
    clickItemToggle() { // only not for Folder include btn area
      if (this.selectModeOpen) {
        if (!this.isFolder || this.displayMode === 'workspaceSelectFolder') {
          this.selected = !this.selected;
          this.$emit('toggle');
        }
      }
    },
    clickFolderToggle() { // only for Folder
      if (this.selectModeOpen) {
        if (this.isFolder) {
          this.selected = !this.selected;
          this.$emit('toggle');
        }
      }
    },
  },
});
