



































































































import Vue, { PropType } from 'vue';
import { MessageEventBus } from '@/libs/EventBus';
import TimeFunction from '@/libs/timeFunctions';
import {
  QuizEventModelv4, QuizEventPreviewModel,
} from '@/apis/models/QuizEventModel';
import QuizEventApi from '@/apis/QuizEventApi';
import QuizEventHandler from '@/handlers/QuizEventHandler';

export default Vue.extend({
  name: 'QuizEventCard',
  components: {
  },
  props: {
    group: {
      type: Object as PropType<QuizEventPreviewModel>,
      required: true,
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultThumbnial: 'https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      copyMessageDisplay: false,
      copyMessageTimeoutId: 0,

      quizEvent: null as QuizEventModelv4 | null, // 本[測驗]的資訊
    };
  },
  computed: {
    previewImage(): string {
      if (this.defaultThumbnial) {
        return `${this.defaultThumbnial}/240`;
      }
      return this.defaultThumbnial;
    },
    dialogId(): string {
      return this.group.uuid;
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
    sentNum(): number {
      if (this.quizEvent && this.quizEvent.pushedQuizGroupList) {
        return this.quizEvent.pushedQuizGroupList.length;
      }
      return 0;
    },
    unsendNum(): number {
      if (this.quizEvent && this.quizEvent.timeMap) {
        return QuizEventHandler.getUnsendNum(this.quizEvent.timeMap);
      }
      return 0;
    },
  },
  async mounted() {
    MessageEventBus.$on('clear-copied-message', this.clearCopyMessageTimeout);

    await QuizEventApi.getQuizEvent(this.group.uuid).then(async (quizEvent) => {
      this.quizEvent = quizEvent;
      // console.log('eventCard 的 quizEvent:', quizEvent);
    });
  },
  methods: {
    checkEnterGroup() {
      if (!this.selectModeOpen) { // 在非選取時刻
        this.enterGroup();
      }
    },
    changeFormat(dateTime: string) {
      const timestamp = Date.parse(dateTime);
      const result = TimeFunction.timestampToDateStringFormat(timestamp / 1000);
      return result;
    },
    enterGroup() {
      this.$store.commit('changeQuizEvent', this.group);
      this.$router.push('/manager/quizevent-preview');
    },
    removeGroup() {
      this.openRemoveModal();
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              // remove operation
              this.$modal.hide('dialog');
              this.$emit('removeMe');
            },
          },
        ],
      });
    },
    copyGroupCode() {
      MessageEventBus.$emit('clear-copied-message');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      console.log('copyText:', this.group.shortId);
      this.$copyText(`${this.group.shortId}`).then(() => {
        this.copyMessageDisplay = true;
        this.copyMessageTimeoutId = setTimeout(this.clearCopyMessage, 1500);
      }, (e) => {
        console.error('複製失败', e.text);
      });
    },
    clearCopyMessage() {
      this.copyMessageDisplay = false;
    },
    clearCopyMessageTimeout() {
      this.clearCopyMessage();
      clearTimeout(this.copyMessageTimeoutId);
    },
  },
});
