





































import Vue, { PropType } from 'vue';

import QuizGroupCard from '@/components/workspace/QuizGroupCard.vue';
import MemberGroupCard from '@/components/workspace/MemberGroupCard.vue';
import QuizEventCard from '@/components/workspace/QuizEventCard.vue';
import SharedObjectCard from '@/components/workspace/SharedObjectCard.vue';
import { WorkspaceObjectModel, WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';

export default Vue.extend({
  components: {
    QuizGroupCard,
    MemberGroupCard,
    QuizEventCard,
    SharedObjectCard,
  },
  props: {
    workspaceObject: {
      type: Object as PropType<WorkspaceObjectModel>,
      required: true,
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      WorkspaceObjectType,
    };
  },
  methods: {
    removeMe() {
      this.$emit('removeMe');
    },
  },
});
