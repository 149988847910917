import { WorkspaceObjectModel } from './WorkspaceObjectModel';

export enum Permission {
  EDITOR = 'EDITOR',
  DETAIL_VIEWER = 'DETAIL_VIEWER',
  GENERAL_VIEWER = 'GENERAL_VIEWER'
}

export interface WorkspaceSoftLinkModel extends WorkspaceObjectModel{
  permission: Permission;
  sourceObject: WorkspaceObjectModel;
}

export class UpdateSoftLinkPayload {
  permission: Permission;

  constructor(permission: Permission) {
    this.permission = permission;
  }
}
