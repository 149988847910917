import { MemberGroupPreviewModel } from '@/apis/models/MemberGroupModel';
import { WorkspaceObjectModel, WorkspaceObjectType } from '@/apis/models/WorkspaceObjectModel';
import { WorkspaceSoftLinkModel } from '@/apis/models/WorkspaceSoftLinkModel';

export default class WorkspaceObjectHandler {
  static getObjectName(workspaceObject: WorkspaceObjectModel) {
    let object = workspaceObject;

    if (object.metadata.type === WorkspaceObjectType.SoftLink) {
      object = (object as WorkspaceSoftLinkModel).sourceObject;
    }

    switch (object.metadata.type) {
      case WorkspaceObjectType.QuizGroup:
      case WorkspaceObjectType.Folder:
      case WorkspaceObjectType.QuizEvent:
        return object.metadata.name;
      case WorkspaceObjectType.MemberGroup:
        return (object as MemberGroupPreviewModel).uniqueName;
      default:
        return '';
    }
  }
}
