


































































































import Vue, { PropType } from 'vue';
import { QuizGroupPreviewModel } from '@/apis/models/QuizGroupModel';

export default Vue.extend({
  name: 'QuizGroupCard',
  components: {
  },
  props: {
    group: {
      type: Object as PropType<QuizGroupPreviewModel>,
      required: false,
      default: null as QuizGroupPreviewModel | null,
    },
    selectModeOpen: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultThumbnial:
      'https://images.unsplash.com/photo-1605522220576-f5566d005f1c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
      copyMessageDisplay: false,
      copyMessageTimeoutId: 0,
    };
  },
  computed: {
    previewImage(): string {
      if (this.group.previewImageUrl) {
        return `${this.group.previewImageUrl}/240`;
      }
      return this.defaultThumbnial;
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
  },
  methods: {
    enterGroup() {
      this.$store.commit('updateEditPermission', true);
      this.$store.commit('changeQuizGroup', this.group);
      this.$router.push('/manager/quizgroup-preview');
    },
    checkEnterGroup() {
      if (!this.selectModeOpen) { // 在非選取時刻
        this.enterGroup();
      }
    },
    removeGroup() {
      this.openRemoveModal();
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              this.$modal.hide('dialog');
              this.$emit('removeMe');
            },
          },
        ],
      });
    },
    openStatistic() {
      const baseUrl = 'https://qsticker.github.io/result-viewer/#/';
      window.open(`${baseUrl}?quizGroupId=${this.group.uuid}`);
    },
    openShareMappingModal() {
      this.$modal.show('manage-share-mapping-modal', {
        sourceObjectId: this.group.uuid,
      });
    },
  },
});
